import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'

function encode (data) {
    return Object.keys(data).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&')
}

export default class Index extends React.Component {
    constructor (props) {
        super(props)
        this.state = { isValidated: false }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...this.state
            })
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }

    render () {
        return (
            <Layout>
                <section className='section'>
                    <div className='container'>
                        <div className='contact-form-wrapper content'>
                            <h1 className='has-text-centered'>Contact</h1>
                            <p className='has-text-centered'>
                                Please get in touch if you have any questions or would like some more information!
                            </p>
                            <form
                                name='contact'
                                method='post'
                                action='/contact/thanks/'
                                data-netlify='true'
                                data-netlify-honeypot='bot-field'
                                onSubmit={this.handleSubmit}
                            >
                                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                                <input type='hidden' name='form-name' value='contact' />
                                <div hidden>
                                    <label>
                                        Don’t fill this out: <input name='bot-field' onChange={this.handleChange} />
                                    </label>
                                </div>
                                <div className='field'>
                                    {/* <label className='label' htmlFor={'name'}>
                                        Your name
                                    </label> */}
                                    <div className='control'>
                                        <input
                                            className='input'
                                            type={'text'}
                                            name={'name'}
                                            onChange={this.handleChange}
                                            id={'name'}
                                            required={true}
                                            placeholder={'Your name'}
                                        />
                                    </div>
                                </div>
                                <div className='field'>
                                    {/* <label className='label' htmlFor={'email'}>
                                        Email
                                    </label> */}
                                    <div className='control'>
                                        <input
                                            className='input'
                                            type={'email'}
                                            name={'email'}
                                            onChange={this.handleChange}
                                            id={'email'}
                                            required={true}
                                            placeholder={'Email address'}
                                        />
                                    </div>
                                </div>
                                <div className='field'>
                                    {/* <label className='label' htmlFor={'message'}>
                                        Message
                                    </label> */}
                                    <div className='control'>
                                        <textarea
                                            className='textarea'
                                            name={'message'}
                                            onChange={this.handleChange}
                                            id={'message'}
                                            required={true}
                                            placeholder={'Please enter your message'}
                                        />
                                    </div>
                                </div>
                                <div className='has-text-centered field'>
                                    <button className='button is-primary' type='submit'>
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}
